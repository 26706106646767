<script>
  import { onMount } from "svelte";
  import { handleResponse } from '../helpers/utils';
  import { accountLoad, wallet } from '../stores/wallet'

  let albedo

  onMount(async () => {
    albedo = await import('@albedo-link/intent').then((res) => res.default)
  })

  function setup() {
    return fetch('/contracts/setup', {
      method: 'POST',
      body: JSON.stringify({
        pubkey: $wallet.pubkey,
        index: Date.now()
      })
    })
    .then(handleResponse)
    .then((res) => 
      albedo.tx({
        xdr: res,
        description: `Setup your wallet for use with Colorglyph`,
        pubkey: $wallet.pubkey,
        network: import.meta.env.VITE_STELLAR_NETWORK,
        submit: true,
      })
    )
    .then(() => accountLoad($wallet?.pubkey))
  }
</script>

<button class="hover:underline" on:click={setup}>Setup</button>