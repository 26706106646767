<script>
  import { countBy } from 'lodash-es'
  import { onMount } from 'svelte'
  import { writable, derived } from 'svelte/store'
  import { wallet, account } from '../stores/wallet'
  import HexGlyph from '../components/HexGlyphs.svelte'
  import newReMint from '../helpers/new-re-mint'

  // TODO:
  // When minting lookup exisiting colors
    // Reuse existing palette account with most reused colors
      // Remember to remove unused colors to alternative palette
      // If no alternative palette exists and the existing isn't a perfect match we need to create one
      // If new mines are merely additive we can keep the singular palette account and just add new mines to it
    // If creating new palette account move any reused colors instead of mining fresh

  let albedo
  
  const width = writable(8)
  const name = writable('')
  const description = writable('')
  const palette = writable([])
  
  const colors = derived(palette, (p) => {
    return Object.entries(countBy(p)).map(([hex, count]) => ({hex, count}))
  })
  
  let red = 0
  let green = 0
  let blue = 0
  let color

  $: {
    if ($width > 64) width.set(64)
    else if (!$width || $width < 1) width.set('')
  }
  $: {
    color = `#${red.toString(16).padStart(2, 0)}${green.toString(16).padStart(2, 0)}${blue.toString(16).padStart(2, 0)}`
  }
  
  onMount(async () => {
    if (localStorage.hasOwnProperty('smol.xyz_createWidth'))
      width.set(localStorage.getItem('smol.xyz_createWidth'))
  
    if (localStorage.hasOwnProperty('smol.xyz_createName'))
      name.set(localStorage.getItem('smol.xyz_createName'))
  
    if (localStorage.hasOwnProperty('smol.xyz_createDescription'))
      description.set(localStorage.getItem('smol.xyz_createDescription'))
  
    if (localStorage.hasOwnProperty('smol.xyz_createPalette'))
      palette.set(JSON.parse(localStorage.getItem('smol.xyz_createPalette')))
  
    width.subscribe((w) => {
      localStorage.setItem('smol.xyz_createWidth', w)
  
      palette.update((p) =>
        new Array(Math.pow(w, 2)).fill('#ffffff').map((white, i) => p[i] || white)
      )
    })
  
    name.subscribe((n) => localStorage.setItem('smol.xyz_createName', n))
    description.subscribe((d) => localStorage.setItem('smol.xyz_createDescription', d))
    palette.subscribe((p) => localStorage.setItem('smol.xyz_createPalette', JSON.stringify(p)))

    albedo = await import('@albedo-link/intent').then((res) => res.default)
  })

  function colorPixel(e, i) {
    if (
      e.buttons === 1
      || e.type === 'mousedown'
    ) {
      if ($palette[i] === color)
        palette.update((p) => {
          p[i] = '#ffffff'
          return p
        })
      else
        palette.update((p) => {
          p[i] = color
          return p
        })
    }
  }
  
  function erase() {
    localStorage.removeItem('smol.xyz_createPalette')
    palette.set(new Array(Math.pow($width, 2)).fill('#ffffff'))
    name.set('')
    description.set('')
  }
  
  async function mint() {
    try {
      await newReMint({
        type: 'new',
        albedo,
        palette: $palette.map((hex) => hex.replace(/[^a-fA-F0-9]+/gi, '')),
        wallet: $wallet,
        account: $account,
        width: $width,
        name: $name,
        description: $description
      })
      
      return erase()
    } catch(err) {
      console.error(err)
    }
  }
</script>
  
<div class="flex flex-col items-start">
  <div class="flex max-w-full mb-2">
    <div class="w-full overflow-y-scroll">
      <ul class="flex flex-wrap" 
        style:width="calc({$width} * 2rem)" 
        style:box-shadow="inset -1px -1px 0 0 #e5e7eb"
      >
        {#each $palette as hex, i (i)}
        <li class="w-8 h-8" 
          style:background-color={/ffffff/gi.test(hex) ? 'transparent' : hex}
          style:box-shadow={`inset 1px 1px 0 0 ${/ffffff/gi.test(hex) ? '#e5e7eb' : hex}`} 
          on:mousedown={(event) => colorPixel(event, i)} 
          on:mouseenter={(event) => colorPixel(event, i)}
        ></li>
        {/each}
      </ul>

      <ul class="flex mt-2 sticky left-0 bottom-0">
        {#each $colors as {hex, count} (hex)}
        <li class="flex flex-col items-center" on:click={() => color = hex}>
          <div class="w-6 h-6" 
            style:background-color="{hex}" 
            style:box-shadow={`inset 0 0 0 1px ${/ffffff/gi.test(hex) ? '#e5e7eb' : hex}`}
          ></div>
          <span class="text-sm">{count}</span>
        </li>
        {/each}
      </ul>
    </div>

    <div class="flex flex-col ml-4">
      <HexGlyph class="mb-2" palette={$palette} width={$width} px={128} />
      <HexGlyph class="mb-2" palette={$palette} width={$width} px={64} />
      <HexGlyph class="mb-2" palette={$palette} width={$width} px={32} />
    </div>
  </div>

  <div class="flex items-center mb-2">
    <span class="mr-2">RGB</span>
    <div class="slider-container flex flex-col">
      <label class="flex items-center mb-1">
        <input type="range" min="0" max="255" bind:value={red} class="slider rounded-full" style:color={color} style:background="linear-gradient(0.25turn, rgb(0, {green}, {blue}), rgb(255, {green}, {blue}))">
        <input type="number" class="flex bg-black text-white rounded ml-1 text-xs py-1 pl-1" min="0" max="255" bind:value={red}>
      </label>

      <label class="flex items-center mb-1">
        <input type="range" min="0" max="255" bind:value={green} class="slider rounded-full" style:color={color} style:background="linear-gradient(0.25turn, rgb({red}, 0, {blue}), rgb({red}, 255, {blue}))">
        <input type="number" class="flex bg-black text-white rounded ml-1 text-xs py-1 pl-1" min="0" max="255" bind:value={green}>
      </label>

      <label class="flex items-center">
        <input type="range" min="0" max="255" bind:value={blue} class="slider rounded-full" style:color={color} style:background="linear-gradient(0.25turn, rgb({red}, {green}, 0), rgb({red}, {green}, 255))">
        <input type="number" class="flex bg-black text-white rounded ml-1 text-xs py-1 pl-1" min="0" max="255" bind:value={blue}>
      </label>
    </div>
  </div>

  <label class="flex items-center mb-2">
    <span class="mr-2">Width</span>
    <input class="w-24 h-8 border-2 border-gray active:border-blue-500 rounded px-2" type="number" bind:value={$width}>
  </label>

  <label class="flex items-center mb-2">
    <span class="mr-2">Title</span>
    <input class="h-8 border-2 border-gray active:border-blue-500 rounded px-2" type="text" bind:value={$name}>
  </label>

  <label class="flex mb-2">
    <span class="mr-2">Story</span>
    <textarea class="border-2 border-gray active:border-blue-500 rounded px-2 py-1" style:min-height="5.25rem" rows="3" bind:value={$description}></textarea>
  </label>

  <div class="mt-2 flex">
    <button on:click={erase} class="mr-2 bg-black text-white py-1 px-2 rounded">Erase</button>
    <button on:click={mint} class="mr-2 bg-black text-white py-1 px-2 rounded">Mint</button>
  </div>
</div>

<style>
  .slider-container {
    width: 250px;
  }
  .slider-container input[type="number"] {
    width: 50px;
    flex-shrink: 0;
  }
  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 30px;
    background: black;
    outline: none;
    background: linear-gradient(to right, var(--stops));
  }
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    background: currentColor;
    cursor: pointer;
    border: 2px solid black;
  }
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    background: currentColor;
    cursor: pointer;
    border: 2px solid black;
  }
</style>