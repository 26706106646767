<script>
import Create from "../pages/Create.svelte";
import Setup from "../pages/Setup.svelte";

import { wallet, account } from '../stores/wallet'
import { connect } from '../components/Header.svelte'
</script>

{#if !$wallet?.pubkey}
  <button class="hover:underline" on:click={connect}>Connect</button>
{:else if !$account?.data_attr?.royaltyindex}
  <Setup />
{:else}
  <Create />
{/if}